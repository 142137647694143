import { Link } from 'react-scroll';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.common.black,
  fontSize: 18,
  marginRight: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration:'none',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    opacity: 1,
    textDecoration: 'none',
    transition: 'all 0.4s ease'
  }
}));


MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

function MenuDesktopItem({ item, pathname, isHome }) {
  const { title, path, children } = item;
  const isActive = pathname === path;
  const isExternal = path.startsWith('https');

  return (
    <>
      {isExternal ? (
        <LinkStyle
          as="a"
          key={title}
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            ...(isHome && { color: 'common.black' }),
            ...(isActive && { color: 'blue.main' })
          }}
        >
          {title}
        </LinkStyle>
      ) : (
        <LinkStyle
          key={title}
          to={path}
          smooth={true}
          duration={500}
          sx={{
            ...(isHome && { color: 'common.black' }),
            ...(isActive && { color: 'blue.main' })
          }}
        >
          {title}
        </LinkStyle>
      )}
    </>
  );
}

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          pathname={pathname}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  );
}
